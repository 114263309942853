import React from 'react';
import DataStore from '../data/DataStore';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import RetailerModel from '../model/ClientModel';
import RetailerWalletModel from '../model/RetailerWalletModel';
import { Dropdown } from 'react-bootstrap';
import URLUtil from '../data/URLUtil';

class ClientWalletView extends React.Component<CommonProps, CommonState> {

    retailerModel: RetailerModel;
    retailerWalletModel: RetailerWalletModel;
    buttonText: string = "Add Credits";

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailerModel = new RetailerModel();
        this.retailerModel.channelId = URLUtil.getPathParamNumber(2);
        this.retailerWalletModel = new RetailerWalletModel();
        this.retailerWalletModel.channelId = URLUtil.getPathParamNumber(2);
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async loadData() {

        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client(this.retailerModel.channelId);
            this.retailerModel = cr.data as RetailerModel;

            this.buttonText = "Stock Update";
        }

        {
            let sa = new ServerAdaptor();
            let cr = await sa.get_client_wallet_info(this.retailerWalletModel.channelId);
            this.retailerWalletModel = cr.data as RetailerWalletModel;
        }
        
        this.updateState();
    }

    async updateWalletAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedRetailerId(this.retailerModel.channelId);
        console.log("edit");
        window.location.href = "/update-client-wallet/"+this.retailerModel.channelId;
    }

    async adjustWalletAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedRetailerId(this.retailerModel.channelId);
        console.log("edit");
        window.location.href = "/adjust-client-wallet/"+this.retailerModel.channelId;
    }

    async moveStockAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedRetailerId(this.retailerModel.channelId);
        console.log("edit");
        window.location.href = "/move-stock";
    }

    async collectionAction(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        DataStore.setSelectedRetailerId(this.retailerModel.channelId);
        console.log("edit");
        window.location.href = "/update-client-wallet/"+this.retailerModel.channelId;
    }

    isStockUpdateEnabled() {
        var flag = true;
        return flag;
    }

    render() {
        let url = "/update-client-wallet/"+this.retailerModel.channelId;
        return (
            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <div className="d-md-flex justify-content-between align-items-center">
                                <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                    <h4 className="mb-0">Wallet Details</h4>
                                </div>
                                <div className="text-center text-md-start">
                                    <a type="button" className="btn btn-sm btn-primary" href="#" onClick={(e) => this.collectionAction(e)}>Add Balance</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card" >
                            <div className="card-body">
                                <h4 className="card-title mb-0">Available balance : {this.retailerWalletModel.amount +" ("+ this.retailerWalletModel.currencyCode+")"}</h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ClientWalletView;