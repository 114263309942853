import React from 'react';
import ServerAdaptor from '../data/ServerAdaptor';
import CommonProps from '../model/CommonProps';
import CommonState from '../model/CommonState';
import "react-datepicker/dist/react-datepicker.css";
import RetailerModel from '../model/ClientModel';
import CountryModel from '../model/CountryModel';
import OperatorModel from '../model/OperatorModel';
import ProductModel from '../model/ProductModel';
import DataStore from '../data/DataStore';
import ListData from '../model/ListData';
import OperatorsModel from '../model/OperatorsModel';
import SelectSearchModel from '../component/SelectSearchModel';
import OptionType from '../component/OptionType';
import SelectSearchComponent from '../component/SelectSearchComponent';
import URLUtil from '../data/URLUtil';

class Products extends React.Component<CommonProps, CommonState> {

    waitDisplay: string = "block";
    tableDisplay: string = "none";

    selectedChannelId = 0;
    selectedCountryId = 0;
    selectedOperatorId = 0;
    selectedCircleId = 0;

    retailers: RetailerModel[] = [];
    retailerSelectModel: SelectSearchModel;

    countries: CountryModel[] = [];
    countrySelectModel: SelectSearchModel;

    operators: OperatorModel[] = [];
    operatorSelectModel: SelectSearchModel;

    products: ProductModel[] = [];

    state: CommonState = {
        time: new Date(),
    };

    constructor(params: CommonProps) {
        super(params);
        this.retailers = [];
        this.countries = [];
        this.operators = [];
        this.retailerSelectModel = new SelectSearchModel();
        this.countrySelectModel = new SelectSearchModel();
        this.operatorSelectModel = new SelectSearchModel();
        this.selectedChannelId = URLUtil.getPathParamNumber(2);
        if(this.selectedChannelId == null) {
            this.selectedChannelId = 0;
        }
        this.loadData();
    }

    updateState() {
        this.setState({
            time: new Date()
        });
    }

    async showWait(show: boolean) {
        if (show) {
            this.waitDisplay = "block";
            this.tableDisplay = "none";
            this.updateState();
        } else {
            this.waitDisplay = "none";
            this.tableDisplay = "block";
        }
    }

    async loadData() {
        // let formattedStartDate = (moment(this.startDate)).format('YYYY-MM-DD HH:mm:ss');
        // let model = { "command": "all", "reqType": "pending", "startTime": formattedStartDate };
        let model = { "command": "all" };
        let sa = new ServerAdaptor();
        this.showWait(true);

        if (this.selectedChannelId == 0) {
            let cr = await sa.get_clients(model);
            let listData = cr.data as ListData;
            this.retailers = listData.clients;
            this.retailerSelectModel.clear();
            this.retailerSelectModel.add("0", "Select Retailer");
            for (var obj of this.retailers) {
                this.retailerSelectModel.add(obj.channelId + "", obj.clientName);
            }
        } else {
            this.countrySelectModel.setSelected("0");
            this.operatorSelectModel.setSelected("0");

            this.loadCountries();
        }

        this.showWait(false);
        this.updateState();
    }

    onRetailerSelectClicked(e: OptionType) {
        this.selectedChannelId = parseInt(e.value);
        this.retailerSelectModel.setSelected(this.selectedChannelId + "");
        this.countrySelectModel.setSelected("0");
        this.operatorSelectModel.setSelected("0");

        this.loadCountries();
    };

    onCountrySelectClicked(e: OptionType) {
        this.selectedCountryId = parseInt(e.value);
        this.countrySelectModel.setSelected(this.selectedCountryId + "");
        this.operatorSelectModel.setSelected("0");

        this.loadOperators();
    };

    onOperatorSelectClicked(e: OptionType) {
        this.selectedOperatorId = parseInt(e.value);
        this.operatorSelectModel.setSelected(this.selectedOperatorId + "");
        for (var operator of this.operators) {
            if(this.selectedOperatorId == operator.operatorId) {
                this.selectedCircleId = operator.defaultCircleId;
            }
        }
    };

    async loadCountries() {

        let model = { "channelId": this.selectedChannelId };
        let sa = new ServerAdaptor();
        let cr = await sa.get_client_countries(this.selectedChannelId);
        let listData = cr.data as ListData;
        this.countries = listData.countries;

        this.countrySelectModel.clear();
        this.countrySelectModel.add("0", "Select Country");
        for (var obj of this.countries) {
            this.countrySelectModel.add(obj.countryId + "", obj.countryName);
        }

        this.updateState();
    }

    async loadOperators() {

        let model = { "channelId": this.selectedChannelId };
        let sa = new ServerAdaptor();
        let cr = await sa.get_client_operators(this.selectedChannelId);
        let operatorsModel = cr.data as OperatorsModel;
        let operators1 = operatorsModel.operators;

        this.operators = [];

        for (var operator of operators1) {
            if (operator.countryId == this.selectedCountryId) {
                this.operators.push(operator);
            }
        }

        this.operatorSelectModel.clear();
        this.operatorSelectModel.add("0", "Select Operator");
        for (var obj of this.operators) {
            this.operatorSelectModel.add(obj.operatorId + "", obj.operatorName);
        }

        this.updateState();
    }

    async searchAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if(this.selectedCircleId == null || this.selectedCircleId == 0) {
            this.selectedCircleId = 1;
        }
        let model = { "channelId": this.selectedChannelId, "countryId": this.selectedCountryId, "operatorId": this.selectedOperatorId, "circleId": this.selectedCircleId };
        let sa = new ServerAdaptor();
        this.showWait(true);
        let cr = await sa.get_products(model);
        let listData = cr.data as ListData;
        this.products = listData.products;
        this.showWait(false);
        this.updateState();
    }

    async orderPlaceAction(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, productId: number) {
        e.preventDefault();

        DataStore.setSelectedRetailerId(this.selectedChannelId);
        DataStore.setSelectedProductId(productId);
        window.location.href = "/order-placing";
    }

    async orderPlaceActionA(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, productId: number) {
        e.preventDefault();

        DataStore.setSelectedRetailerId(this.selectedChannelId);
        DataStore.setSelectedProductId(productId);
        window.location.href = "/order-placing";
    }

    getRetailorProperty() {
        if (this.selectedChannelId == 0) {
            return 'block';
        }
        return 'none';
    }

    getValidity(validity: string) {
        if (validity == '0') {
            return 'N.A';
        }
        return validity;
    }

    render() {

        return (

            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Products</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-3 mt-0" style={{ display: this.getRetailorProperty() }}>
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Retailer</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.retailerSelectModel} onSelectClicked={(e) => this.onRetailerSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Country</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.countrySelectModel} onSelectClicked={(e) => this.onCountrySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Operator</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.operatorSelectModel} onSelectClicked={(e) => this.onOperatorSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pt-5">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.searchAction(e)}>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.waitDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="spinner-border m-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.tableDisplay }}>
                    <div className="col-lg-9 col-md-9 col-12">
                        <div className="card h-100">

                            <div className="table-responsive">
                                <table className="table text-nowrap">

                                    <tbody>
                                        {this.products.map((obj, index) =>


                                            <tr>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ms-3 lh-1">
                                                            <h5 className=" mb-1">{obj.currencyName} {obj.price}</h5>
                                                            <p className="mb-0 mt-3">{obj.sendCurrencyName} {obj.sendPrice}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <a onClick={(e) => this.orderPlaceActionA(e, obj.productId)} href="#">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ms-3 lh-1">
                                                            <h5 className=" mb-1">{obj.productName}</h5>
                                                            <p className="mb-0 mt-3">{obj.description}</p>
                                                        </div>
                                                    </div>
                                                    </a>
                                                </td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }

    renderBkp() {

        return (

            <div className="container-fluid p-6">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="border-bottom pb-4 mb-4">
                            <h3 className="mb-0 fw-bold">Products</h3>
                        </div>
                    </div>
                </div>

                <div className="row mb-6">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">

                            <div className="card-body">
                                <form className="row g-3 needs-validation">
                                    <div className="col-md-3 mt-0" style={{ display: this.getRetailorProperty() }}>
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Retailer</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.retailerSelectModel} onSelectClicked={(e) => this.onRetailerSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Country</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.countrySelectModel} onSelectClicked={(e) => this.onCountrySelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-0">
                                        <label data-for="location" className="col-sm-4 col-form-label form-label">Operator</label>
                                        <div className="col-md-8 col-12">
                                            <SelectSearchComponent model={this.operatorSelectModel} onSelectClicked={(e) => this.onOperatorSelectClicked(e)}></SelectSearchComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-3 pt-5">
                                        <button className="btn btn-primary" type="submit" onClick={(e) => this.searchAction(e)}>Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.waitDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="spinner-border m-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-6" style={{ display: this.tableDisplay }}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-header bg-white py-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                    <div className="mb-3 mb-lg-0 text-center text-sm-start">
                                        <h4 className="mb-0">Products</h4>
                                    </div>
                                    <div className="text-center text-md-start">

                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-nowrap">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Sno</th>
                                            <th>Price</th>
                                            <th>productName</th>
                                            <th>validity</th>
                                            <th>Send Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.products.map((obj, index) =>
                                            <tr>
                                                <td className="align-middle">
                                                    <h5 className=" mb-1">{(index + 1)}.</h5>
                                                </td>
                                                <td className="align-middle"><h5 className=" mb-1">{obj.price}</h5></td>
                                                <td className="align-middle">{obj.productName}</td>
                                                <td className="align-middle">{obj.validity}</td>
                                                <td className="align-middle">{obj.sendPrice}</td>
                                                <td className="align-middle">
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.orderPlaceAction(e, obj.productId)}>Recharge</button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default Products;